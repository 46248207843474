import instance from "./request";

//获取验证码请求
export const GetCaptchaCodeApi = () => instance.get("/api/front/getCaptchaImage")

//登录请求  csrfmiddlewaretoken:params.csrfToken
export const LoginApi = (params) => instance.post("/api/solarAdmin/login_user/inter/nice/miss/z5y6q8dc", params)

//获取csrf_token
export const GetCsrfTokenApi = () => instance.post("/api/solarAdmin/get-csrf/inter/nice/miss/z5y6q8dc")

//获取route路由信息
export const GetRoutersApi = () => instance.post("/api/solarAdmin/user-role-permissions/inter/nice/miss/z5y6q8dc")  //此处的权限将来要改成动态的，根据用户token获取用户有哪些，传回
//export const GetRoutersApi = () => instance.post("/api/solarAdmin/permission-list/inter/nice/miss/z5y6q8dc") //此处是全部，用来编辑的


//获取节点列表
export const GetNodeListApi = () => instance.post("/api/solarAdmin/permission-list/inter/nice/miss/z5y6q8dc") //此处是全部，用来编辑的

//获取登录用户信息
export const GetUserInfoApi = () => instance.post("/api/solarAdmin/user-info/inter/nice/miss/z5y6q8dc")

//保存base setting
export const SaveBaseSettingApi = (params) => instance.post('/api/solarAdmin/xxxxxxxxxxxxxxxxx',params)

//获取产品列表
export const GetProductListApi = (params) => instance.post("/api/solarAdmin/product-info/inter/nice/miss/z5y6q8dc", params)

//获取待审核产品列表
export const GetProductApproveListApi = (params) => instance.post("/api/solarAdmin/product-approve-list/inter/nice/miss/z5y6q8dc",params)

//获取待审核套餐列表
export const GetSolutionApproveListApi = (params) => instance.post("/api/solarAdmin/solution-approve-list/inter/nice/miss/z5y6q8dc",params)

//获取待审核套餐详情
export const GetApproveSolutionByProductIdApi = (params) => instance.post("/api/solarAdmin/solution-approve-content/inter/nice/miss/z5y6q8dc",params)

//套餐审核通过
export const UpdateSolutionApproveApi = (params) => instance.post("/api/solarAdmin/accept/inter/nice/miss/z5y6q8dc/solution/4545ydf/approve/status", params)

//获取待审核产品详情
export const GetApproveProductByProductIdApi = (params) => instance.post("/api/solarAdmin/product-approve-content/inter/nice/miss/z5y6q8dc",params)

//获取待审核套餐详情
export const GetApproveSolutionBySolutionIdApi = (params) => instance.post("/api/solarAdmin/solution-approve-content/inter/nice/miss/z5y6q8dc",params)

//获取解决方案详情
export const GetSolutionBySolutionIdApi = (params) => instance.post("/api/solarAdmin/solution-content/inter/nice/miss/z5y6q8dc",params)

//产品审核通过
export const UpdateProductApproveApi = (params) => instance.post("/api/solarAdmin/accept/inter/nice/miss/z5y6q8dc/product/4545ydf/approve/status", params)

//获取产品信息简单列表
export const GetProductSimpleListApi = (params) => instance.post("/api/solarAdmin/product-info/inter/nice/miss/z5y6q8dc/simple",params)

//通过id获取product详情
//export const GetProductDetialByIdApi = (params) => instance.post("/api/solarAdmin/product-detail/inter/nice/miss/z5y6q8dc",params)

//通过product_id获取product详情
export const GetProductByProductIdApi = (params) => instance.post('/api/solarAdmin/product-detail/inter/nice/miss/z5y6q8dc',params)

//获取栏目列表
export const GetCategoryListApi = (params) => instance.post("/api/solarAdmin/query-category-list/inter/nice/miss/z5y6q8dc",params)

//获取文章列表
export const GetArticleListApi = (params) => instance.post("/api/solarAdmin/query-article-list/inter/nice/miss/z5y6q8dc",params)

//获取文章内容
export const GetArticleByIdApi = (params) => instance.post("/api/solarAdmin/query_article-content/inter/nice/miss/z5y6q8dc",params)

//添加更新文章
export const AddEditArticleApi = (params) => instance.post("/api/solarAdmin/query-article-save/inter/nice/miss/z5y6q8dc",params)

//获取栏目对象
export const GetCategoryByIdApi = (params) => instance.post("/api/solarAdmin/query-category-content/inter/nice/miss/z5y6q8dc",params)

export const GetCarouselListByCategoryIdApi = (params) => instance.post("/api/solarAdmin/query-carousel-list/inter/nice/miss/z5y6q8dc",params)

export const GetCarouselByIdApi = (params) => instance.post("/api/solarAdmin/get-carousel-content/inter/nice/miss/z5y6q8dc",params)

export const AddEditCarouselApi = (params) => instance.post("/api/solarAdmin/query-save-carousel/inter/nice/miss/z5y6q8dc",params)

export const DelCategoryApi = (params) => instance.post('/api/solarAdmin/category-delete/inter/nice/miss/z5y6q8dc',params)

//获取用户列表
export const GetUserListApi = (params) => instance.post("/api/solarAdmin/user-info-list/inter/nice/miss/z5y6q8dc",params)

//获取前台客户列表
export const GetCustomerListApi = (params) => instance.post("/api/solarAdmin/customer-list/inter/nice/miss/z5y6q8dc",params)

//获取角色列表
export const GetRoleListApi = (params) => instance.post("/api/solarAdmin/role-list/inter/nice/miss/z5y6q8dc",params)

//export const SaveEditNodeFormApi = (params) => instance.post("/api/saveEditNodeFormApi",params)

//添加用户
export const SaveUserApi = (params) => instance.post("/api/solarAdmin/user-save/inter/nice/miss/z5y6q8dc",params)

//编辑用户
export const UpdateUserApi = (params) => instance.post("/api/solarAdmin/user-modify/inter/nice/miss/z5y6q8dc",params)

//删除用户
export const DelUserApi = (params) => instance.post("/api/solarAdmin/user-delete/inter/nice/miss/z5y6q8dc",params)

//给角色配置权限
export const SavePermissionApi = (params) => instance.post('/api/solarAdmin/role-add-permissions/inter/nice/miss/z5y6q8dc',params)

//添加角色
export const AddRoleApi = (params) => instance.post('/api/solarAdmin/role-save/inter/nice/miss/z5y6q8dc',params)

//编辑角色
export const UpdateRoleApi = (params) => instance.post('/api/solarAdmin/role-modify/inter/nice/miss/z5y6q8dc',params)

export const DelRoleApi = (params) => instance.post('/api/solarAdmin/role-delete/inter/nice/miss/z5y6q8dc',params)

//添加产品
export const AddProductApi = (params) => instance.post('/api/solarAdmin/accept/inter/nice/miss/z5y6q8dc/product/4545ydf/manage/a',params)

//编辑产品
export const UpdateProductApi = (params) => instance.post('/api/solarAdmin/accept/inter/nice/miss/z5y6q8dc/product/4545ydf/manage/dp',params)

//删除产品
export const DelProductByIdApi = (params) => instance.post('/api/solarAdmin/product-delete/inter/nice/miss/z5y6q8dc/product/4545ydf/manage/dp',params)

//删除套餐
export const DelSolutionByIdApi = (params) => instance.post('/api/solarAdmin/solution-delete/inter/nice/miss/z5y6q8dc/solution/4545ydf/manage/dp',params)

//编辑栏目
export const UpdateCategoryApi = (params) => instance.post('/api/solarAdmin/category-modify/inter/nice/miss/z5y6q8dc',params)

//添加栏目
export const AddCategoryApi = (params) => instance.post('/api/solarAdmin/category-save/inter/nice/miss/z5y6q8dc',params)

//添加节点
export const AddNodeApi = (params) => instance.post('/api/solarAdmin/permission-save/inter/nice/miss/z5y6q8dc',params)

//编辑节点
export const UpdateNodeApi = (params) => instance.post('/api/solarAdmin/permission-modify/inter/nice/miss/z5y6q8dc',params)

//删除节点
export const DelNodeApi = (params) => instance.post('/api/solarAdmin/permission-delete/inter/nice/miss/z5y6q8dc',params)

//获取套餐列表
export const GetSolutionListApi = (params) => instance.post('/api/solarAdmin/solution-list/inter/nice/miss/z5y6q8dc',params)

//添加套餐
export const AddSolutionApi = (params) => instance.post('/api/solarAdmin/solution-save/inter/nice/miss/z5y6q8dc',params)

//编辑套餐
export const UpdateSolutionApi = (params) => instance.post('/api/solarAdmin/solution-save/inter/nice/miss/z5y6q8dc',params)

//套餐配置关联产品列表
export const GetSolutionProductListApi = (params) => instance.post('/api/solarAdmin/solution-product-list/inter/nice/miss/z5y6q8dc',params)

//套餐配置产品关联 添加
export const AddSolutionProductApi = (params) => instance.post('/api/solarAdmin/solution-product-save/inter/nice/miss/z5y6q8dc',params)

//更新关联的产品
export const UpdateSolutionProductApi = (params) => instance.post('/api/solarAdmin/solution-product-modify/inter/nice/miss/z5y6q8dc',params)

//获取订单列表
export const GetOrderListApi = (params) => instance.post('/api/solarAdmin/query-order-list/inter/nice/miss/z5y6q8dc',params)

//删除套餐关联产品
export const DeleteSolutionProductApi = (params) => instance.post('/api/solarAdmin/solution-product-delete/inter/nice/miss/z5y6q8dc',params)

//获取订单详情
export const GetOrderDetailApi = (params) => instance.post('/api/solarAdmin/order-detail-list/inter/nice/miss/z5y6q8dc',params)

//订单确认-财务
export const DoOrderApproveApi = (params) => instance.post('/api/solarAdmin/add-order-approve/inter/nice/miss/z5y6q8dc',params)

//获取订单审核记录
export const GetOrderApproveLogApi = (params) => instance.post('/api/solarAdmin/order-approve-list/inter/nice/miss/z5y6q8dc',params)

//获取发货单列表
export const GetDeliveryListApi = (params) => instance.post('/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/file/a/shipments', params)

//获取打折规则列表
export const GetDiscountListApi = (params) => instance.post("/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/r/discount", params)

//修改打折规则
export const UpdateDiscountApi = (params) => instance.post("/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/u/discount", params)

//创建打折规则
export const CreateDiscountApi = (params) => instance.post("/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/c/discount", params)

//删除打折规则
export const DeleteDiscountApi = (params) => instance.post("/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/d/discount",params)

//获取满减列表
export const GetFullReductionListApi = (params) => instance.post("/api/solarAdmin/accept/inter/nice/miss/z5y6q8dc/manage/r/full/minus", params)

//修改满减列表
export const UpdateFullReductionListApi = (params) => instance.post("/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/u/full/minus", params)

// 添加满减信息
export const CreateFullReductionListApi = (params) => instance.post("/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/c/full/minus", params)

// 删除满减信息
export const DeleteFullReductionListApi = (params) => instance.post("/api/solarAdmin/accept/inter/nice/miss/z5y6q8dc/manage/d/full/minus", params)