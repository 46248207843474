import axios from "axios"
import {Message} from 'element-ui' //Loading, 
import router from "@/router"
const instance = axios.create({
    //baseURL: "http://127.0.0.1:8000",
    //baseURL: "http://192.168.1.75:8001",
    //baseURL: "http://192.168.1.106:8008",

    //baseURL: "http://192.168.1.66:8008",
    baseURL: "https://easysolarsupply.com",
    timeout: 10000
})

instance.interceptors.request.use(config => {
    const token = localStorage.getItem("authorization-token");
    
    if(config.data != undefined && config.data.CSRFToken != undefined){
        config.headers['CSRFToken'] = config.data.CSRFToken;
        delete config.data.CSRFToken;
    }

    if(token && !config.url.endsWith("/login")){
        //
        config.headers['Authorization'] = "Bearer " + token;
    }
    return config;
},err => {

    return Promise.reject(err)
})

instance.interceptors.response.use(res => {
    
    //Loading.service().close();
    if(res.data.code != 200){

        //token过期，定义为403
        if(res.data.code == 403 || res.data.code == 402){
            setTimeout(()=>{
                localStorage.removeItem("authorization-token");
                localStorage.removeItem("userInfo");
                router.push("/admin/login");
            },1500);
        } else {
            //405 CSRFToken失效 
            Message.error(res.data.msg);
        }
        Message.error(res.data.msg);
        return false;
    }
    return res.data;
},err => {
    //Loading.service().close();
    //return Promise.reject(err)
    
    Message.error(err.name + ": " + err.message + " data: " + err.response.data.msg);
    if(err.status == 402){
        setTimeout(()=>{
            localStorage.removeItem("authorization-token");
            localStorage.removeItem("userInfo");
            router.push("/admin/login");
        },1500);
    }
    return //Promise.reject(err);
})

export default instance