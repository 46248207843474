
export function productTypeOptionsMapper(){
    return [{value:0,label:"Inverter"},{value:1,label:"PV Panel"},{value:2,label:"Battery"},{value:3,label:"EV Charger"},{value:4,label:"Intentional Deposit"}];
}

export function categoryOptionsMapper(){
    return [{value:0,label:"Residential"},{value:1,label:"Commercial"},{value:2,label:"Both"}];
}

export function certificationOptionsMapper(){
    return [{value:0,label:'UKCA',img:""},{value:1,label:'ENERGY STAR',img:""},{value:2,label:'RoHS',img:""},{value:3,label:'CE',img:""},]
}

export function battriesOptionsMapper(){
    return [{value:false,label:'Without Battery'},{value:true,label:"With Battery"}];
}

export function constructionModeOptionsMapper(){
    return [{value:0,label:"roof"},{value:1,label:"car port"},{value:2,label:"roof + car port"}];
}

export function orderTypeOptionsMapper(){
    return [{value:0,label:"Product order"},{value:1,label:"Deposit order"}];
}

export function orderStatusOptionsMapper(){
    return [{value:-1,label:"Order cancellation"},{value:0,label:"Unpaid payment"},{value:1,label:"Paid already"},{value:2,label:"Pending shipment"},{value:3,label:"Already shipped"},{value:4,label:"Received goods"},{value:5,label:"Order completion"}]
}

export function isShelvesOptionsMapper(){
    return [{value:true,label:"In Stock"},{value:false,label:"Out of Stock"}];
}