import Vue from 'vue'
//import 'reset-css'
import App from './App.vue'
import router from "./router"
import './plugins/element.js'
import store from './store'
import "@/utils/filters"



Vue.config.productionTip = false
//Vue.prototype.$uploadBaseUrl = 'http://192.168.1.66:8008/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/file/a/upload';
Vue.prototype.$uploadBaseUrl = 'https://easysolarsupply.com/api/solarAdmin/accept/inter/file/miss/z5y6q8dc/manage/file/a/upload';
//Vue.prototype.$TinyMCE = 'hf9cag91h7y1twpr31bgtzvu41xhs9vnemrmr6bj22iz0kig';
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
